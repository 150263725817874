<template>
  <v-container>
    <v-row class="my-4">
      <registrations-count-tile />
    </v-row>
    <v-row class="my-4">
      <registrations-age-tile />
    </v-row>
    <v-row class="my-4">
      <registrations-allergy-tile />
    </v-row>
    <v-row class="my-4">
      <registrations-disease-tile />
    </v-row>
  </v-container>
</template>

<script>
import participantsModule from '../../store/modules/participants';
import RegistrationsCountTile from '../tiles/RegistrationsCountTile.vue';
import RegistrationsAgeTile from '../tiles/RegistrationsAgeTile.vue';
import RegistrationsAllergyTile from '../tiles/RegistrationsAllergyTile.vue';
import RegistrationsDiseaseTile from '../tiles/RegistrationsDiseaseTile.vue';

export default {
  components: {
    RegistrationsCountTile,
    RegistrationsAgeTile,
    RegistrationsAllergyTile,
    RegistrationsDiseaseTile
  },

  data: () => ({
    checking: false,
    heartbeats: []
  }),

  created() {
    /* ensure that the store module for login is registered */
    if (!this.$store.hasModule('participantsModule')) {
      this.$store.registerModule('participantsModule', participantsModule);
      console.log('Module [participantsModule] added to store');
    }
    this.readParticipants();
  },

  methods: {
    readParticipants() {
      this.$store.dispatch('ACT_GET_PARTICIPANTS').catch(error => {
        this.$root.$info.open(error);
      });
    }
  }
};
</script>
