<template>
  <v-card class="mx-auto" color="grey lighten-4" width="600">
    <v-card-title>
      <v-icon color="primary" class="mr-12" size="64">
        mdi-account-search-outline
      </v-icon>
      <v-row align="start">
        <div>
          <span class="display-3 font-weight-black" v-text="averageAge"></span>
          <div class="caption grey--text text-uppercase">&#8709; Alter</div>
        </div>
        <div class="mx-8">
          <span
            class="display-1 font-weight-black red--text"
            v-text="female || '—'"
          ></span>
          <div class="caption text-uppercase red--text">&#8709; Mädchen</div>
        </div>
        <div class="mx-2">
          <span
            class="display-1 font-weight-black blue--text"
            v-text="male || '—'"
          ></span>
          <div class="caption text-uppercase blue--text">&#8709; Jungen</div>
        </div>
      </v-row>

      <v-spacer></v-spacer>

      <v-btn
        icon
        class="align-self-start"
        size="28"
        @click="$router.push('/participants')"
      >
        <v-icon>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  computed: {
    averageAge() {
      const ages = this.$store.state.participantsModule.participants.filter(
        e => e.birthDay.length > 0
      );
      let age = 0;
      ages.forEach(element => {
        age += moment().diff(moment(element.birthDay, 'DD.MM.YYYY'), 'years');
      });
      return (age / ages.length).toFixed(1);
    },
    male() {
      const ages = this.$store.state.participantsModule.participants.filter(
        e => e.birthDay.length > 0 && e.gender === 'male'
      );
      let age = 0;
      ages.forEach(element => {
        age += moment().diff(moment(element.birthDay, 'DD.MM.YYYY'), 'years');
      });
      return (age / ages.length).toFixed(1);
    },
    female() {
      const ages = this.$store.state.participantsModule.participants.filter(
        e => e.birthDay.length > 0 && e.gender === 'female'
      );
      let age = 0;
      ages.forEach(element => {
        age += moment().diff(moment(element.birthDay, 'DD.MM.YYYY'), 'years');
      });
      return (age / ages.length).toFixed(1);
    }
  }
};
</script>
