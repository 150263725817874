<template>
  <div class="mx-4" style="text-align: center;">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          :class="`display-1 font-weight-black ${myObj.c}`"
          :style="myObj.v.length ? 'cursor:pointer' : 'cursor:default'"
          v-on="on"
          v-text="myObj.v.length || '—'"
        ></span>
        <div :class="`caption text-uppercase ${myObj.c}`">{{ myObj.l }}</div>
      </template>
      <v-list v-if="myObj.v.length">
        <v-list-item v-for="(item, index) in myObj.v" :key="index">
          <v-list-item-title style="cursor:pointer" @click="jumpToChild(item)"
            >{{ item.firstName }} {{ item.familyName }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    myObj: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  methods: {
    jumpToChild(item) {
      this.$store.commit('MUT_IS_CHILD_SELECTED', true);
      this.$router.push(`/participants/display/${item.id}`);
    }
  }
};
</script>
