<template>
  <v-card class="mx-auto" color="grey lighten-4" width="600">
    <v-card-title>
      <v-icon color="primary" class="mr-12" size="64">
        mdi-account-search-outline
      </v-icon>
      <v-row align="start">
        <dash-board-count
          :my-obj="{ l: 'Epilep.', v: epilepsy || '—', c: 'red--text' }"
        />
        <dash-board-count
          :my-obj="{ l: 'Schilddr.', v: thyroid || '—', c: 'blue--text' }"
        />
        <dash-board-count
          :my-obj="{ l: 'Sonst.', v: misc || '—', c: 'lime--text' }"
        />
      </v-row>

      <v-spacer></v-spacer>

      <v-btn
        icon
        class="align-self-start"
        size="28"
        @click="$router.push('/participants')"
      >
        <v-icon>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
import DashBoardCount from '../elements/DashbordCount.vue';

export default {
  components: { DashBoardCount },

  computed: {
    epilepsy() {
      return this.$store.state.participantsModule.participants.filter(
        e => e.medicData.dieseases.epilepsy
      );
    },
    thyroid() {
      return this.$store.state.participantsModule.participants.filter(
        e => e.medicData.dieseases.thyroid
      );
    },
    misc() {
      return this.$store.state.participantsModule.participants.filter(
        e => e.medicData.dieseases.misc.flag
      );
    }
  }
};
</script>
