<template>
  <v-card class="mx-auto" color="grey lighten-4" width="600">
    <v-card-title>
      <v-icon color="primary" class="mr-12" size="64">
        mdi-account-search-outline
      </v-icon>
      <v-row align="start">
        <dash-board-count
          :my-obj="{ l: 'Zöliakie', v: celiac || '—', c: 'red--text' }"
        />
        <dash-board-count
          :my-obj="{ l: 'Lakt-Int.', v: lactose || '—', c: 'cyan--text' }"
        />
        <dash-board-count
          :my-obj="{ l: 'Nuss-All.', v: nut || '—', c: 'pink--text' }"
        />
        <dash-board-count
          :my-obj="{ l: 'Obst-All.', v: fruit || '—', c: 'purple--text' }"
        />
      </v-row>

      <v-spacer></v-spacer>

      <v-btn
        icon
        class="align-self-start"
        size="28"
        @click="$router.push('/participants')"
      >
        <v-icon>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
import DashBoardCount from '../elements/DashbordCount.vue';

export default {
  components: { DashBoardCount },
  computed: {
    celiac() {
      return this.$store.state.participantsModule.participants.filter(
        e => e.medicData.allergies.celiac
      );
    },
    lactose() {
      return this.$store.state.participantsModule.participants.filter(
        e => e.medicData.allergies.lactose
      );
    },
    nut() {
      return this.$store.state.participantsModule.participants.filter(
        e => e.medicData.allergies.nut.flag
      );
    },
    fruit() {
      return this.$store.state.participantsModule.participants.filter(
        e => e.medicData.allergies.fruit.flag
      );
    }
  }
};
</script>
