<template>
  <v-card class="mx-auto" color="grey lighten-4" width="600">
    <v-card-title>
      <v-icon color="primary" class="mr-12" size="64">
        mdi-account-search-outline
      </v-icon>
      <v-row align="start">
        <div>
          <span
            class="display-3 font-weight-black"
            v-text="$store.state.participantsModule.participants.length"
          ></span>
          <div class="caption grey--text text-uppercase">Anmeldungen</div>
        </div>
        <dash-board-count
          :my-obj="{ l: 'Mädchen', v: female || '—', c: 'red--text' }"
        />
        <dash-board-count
          :my-obj="{ l: 'Jungen', v: male || '—', c: 'blue--text' }"
        />
      </v-row>

      <v-spacer></v-spacer>

      <v-btn
        icon
        class="align-self-start"
        size="28"
        @click="$router.push('/participants')"
      >
        <v-icon>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
import DashBoardCount from '../elements/DashbordCount.vue';

export default {
  components: { DashBoardCount },
  computed: {
    male() {
      return this.$store.state.participantsModule.participants.filter(
        e => e.gender === 'male'
      );
    },
    female() {
      return this.$store.state.participantsModule.participants.filter(
        e => e.gender === 'female'
      );
    }
  }
};
</script>
